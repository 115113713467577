import gql from 'graphql-tag';

export const GET_GALLERY_PLACEMENTS_BY_IDENTIFIER = gql`
  query PlacementsForGalleryByIdentifier($identifier: String!) {
    placementsForGalleryByIdentifier(identifier: $identifier)
  }
`;

export const UGC_GALLERY_FILTER = gql`
  query UgcGalleryFilter(
    $perPage: Int
    $page: Int
    $ugcTags: [String]
    $tagGroups: [String]
    $productTypes: [String]
    $colorTags: [String]
    $colors: [ID]
    $relatedProducts: [String]
    $sorter: String
    $searchQuery: String
    $gallery: ID!
  ) {
    ugcGalleryFilter(
      perPage: $perPage
      page: $page
      ugcTags: $ugcTags
      tagGroups: $tagGroups
      productTypes: $productTypes
      colorTags: $colorTags
      colors: $colors
      sorter: $sorter
      searchQuery: $searchQuery
      relatedProducts: $relatedProducts
      gallery: $gallery
    ) {
      page
      perPage
      nextPage
      totalCount
      ugcTags
      colorTags
      colors
      tagGroups
      sorter
      relatedProducts
      placementsForGallery {
        id
        layout
        rect
        index
        ugc {
          id
          related_products
          attribution
          alt_text
          link
          filename
          tags {
            id
            name
          }
          file {
            url
          }
        }
      }
    }
  }
`;

export const GET_GALLERY_PLACEMENT = gql`
  query UgcPlacement($id: ID!) {
    ugcGalleryPlacement(id: $id) {
      id
      rect
      index
      ugc {
        id
        related_products
        attribution
        alt_text
        link
        filename
        file {
          url
        }
      }
    }
  }
`;
