import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';

import DynamicUgcGallery from './DynamicPage/DynamicUgcGallery';
import useAdjustedHeadingSize from './useAdjustedHeadingSize';

const ModuleSliderGallery = ({
  identifier,
  mainProductTitle,
  mainProductDescription,
  activePlacementId,
  onViewPlacement,
  ...rest
}) => {
  const largeFontStyle = useAdjustedHeadingSize(1);
  return (
    <Box {...rest}>
      <Box
        pad="medium"
        border={{ side: 'bottom', size: 'small', color: 'black' }}
        align="center"
      >
        <Heading
          margin="none"
          textAlign="center"
          level={2}
          style={largeFontStyle}
        >
          Your Backdrops
        </Heading>
      </Box>
      <Box>
        <DynamicUgcGallery
          blok={{
            identifier: identifier,
            mainProductTitle: mainProductTitle,
            mainProductDescription: mainProductDescription,
            activePlacementId: activePlacementId,
            onViewPlacement: onViewPlacement,
          }}
        />
      </Box>
    </Box>
  );
};

ModuleSliderGallery.propTypes = {
  identifier: PropTypes.string,
  mainProductTitle: PropTypes.string,
  mainProductDescription: PropTypes.string,
  activePlacementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onViewPlacement: PropTypes.func,
};

export default memo(ModuleSliderGallery);
